import React from 'react';
import Theme from './Themes';
let configTheme = Theme.getThemeByLocation()  

const UpperLineBrand = () => {
  return (
    <div style={{background: `${configTheme.primary_color}`, height: '1%', width: '100%'}} />
  )
}

export default UpperLineBrand;
