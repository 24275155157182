// import { DEFAULT_THEME} from '../../Config/Constant/config';

// const DefaultPaymentezTheme = {
//   "name": 'paymentez',
//   "primary_color": "#00BF84",
//   "primary_color_contrast": "rgba(255,255,255,0.85)",
//   "primary_color_text_over": "white",
//   "secondary_color": "#545454",
//   "side_bar_color": "#F8F8F8",
//   "background_image": null,
//   "site": "https://paymentez.com/",
//   "favicon": "/favicon.ico",
//   "domain": "localhost",
//   "logo": "https://cdn.paymentez.com/img/Logo2x.png"
// };

const CityParkingTheme = {
  "name": 'cityparking',
  "primary_color": "#1898d6",
  "primary_color_contrast": "rgba(255,255,255,0.85)",
  "primary_color_text_over": "white",
  "secondary_color": "#545454",
  "side_bar_color": "#F8F8F8",
  "background_image": null,
  "site": "https://city-parking.com//",
  "favicon": "/faviconCP.ico",
  "domain": "cityparking",
  "logo": "https://cdn.paymentez.com/img/city_parking_movilidad_urbana.png"
};

const Themes = {
  cityparking: CityParkingTheme,
  localhost: CityParkingTheme,

  getThemeByLocation: () => {
    // const hostname = window.location.hostname;
    // let domain = hostname === 'localhost' ? hostname : hostname.split('.')[1];
    // if (domain === 'netlify' && DEFAULT_THEME) {
    //   domain = DEFAULT_THEME
    // }
    return CityParkingTheme
  }
};

export default Themes;

