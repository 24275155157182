import React from 'react';
import { Alert, Row, Col, Card, Form, Button, Spinner } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { Formik, Field } from 'formik';
import * as Yup from "yup";
import { FormSchema } from "../../Config/Utils/ValidationSchema";
import { NOCCAPI_URL } from "../../Config/Constant/config"
import Theme from './Themes';
import { getAuth } from '../../Auth/Auth';
let configTheme = Theme.getThemeByLocation()
const axios = require('axios').default;

const initialValues = {
  nombre: '',
  apellido: '',
  cedula: '',
  numeroReferencia: '',
  monto: '',
  telefono: '',
  email: '',
  observaciones: ''
}

const validationSchema = (values) => {
  return Yup.object().shape({
      nombre: Yup.string().required(
        "Nombre requerido"
      ),
      apellido: Yup.string().required(
        "Apellido requerido"
      ),
      cedula: Yup.string().required(
        "Cédula requerida"
      ),
      numeroReferencia: Yup.string().required(
        "Número de referencia requerido"
      ),
      monto: Yup.string().required(
        "Monto requerido"
      ),
      telefono: Yup.string().required(
        "Número de teléfono requerido"
      ),
      email: Yup.string()
        .email("Escribe un correo electrónico válido")
        .required("Este campo es obligatorio"),
    })
}

class Contain extends FormSchema {
  constructor(){
    super();
    this.state ={
      error: false,
      loading: false,
      disabled: false
    }
  }

  linkToPay = async(values) => {
    let token = await getAuth()
    this.setState({ loading: true, disabled: true})
    axios({
      method: 'post',
      url: `${NOCCAPI_URL}/linktopay/init_order/`,
      headers: {
        'auth-token': token,
        'content-type': 'application/json',
      },
      data: {
        user: {
          id: "117",
          email: values.email,
          name: values.nombre,
          last_name: values.apellido
      },
        order: {
          dev_reference: values.numeroReferencia.toString(),
          description: "CityParking " + values.observaciones,
          amount: Number(values.monto),
          installments_type: -1,
          currency: "COP",
          taxable_amount: 0,
          vat: 0,
          vat_base: 0,
      },
        configuration: {
          partial_payment: false,
          expiration_days: 1,
          allowed_payment_methods: ["All"],
          success_url: "https://city-parking.com/",
          failure_url: "https://city-parking.com/",
          pending_url: "https://city-parking.com/",
          review_url: "https://city-parking.com/"
        }
      }
    })
    .then(response => {
      if(response && response.data && response.data.data && response.data.data.payment && response.data.data.payment.payment_url) {
        return window.location.replace(`${response.data.data.payment.payment_url}`)
      }
      this.setState({ loading: false, disabled: false})
    })
    .catch(error => {
      this.setState({
        error: true,
        loading: false,
        disabled: false
      })
      console.log(error)
    }) 
  }

  validateNumber = (value) => {
    let error;
    if(!value) {
      error = 'Este campo es obligatorio';
    } else if(!/^[0-9]+([,\.][0-9]*)?$/i.test(value)) {
      error = 'Ingresa número válido'
    }
    return error
  }

  render () {
    return(
      <div className="contain">
        <Alert variant='danger' show={this.state.error}>
          Parece que algo salió mal
        </Alert>
        <Row>
          <Col md="12">
            <Card>
              <Card.Header className="d-flex justify-content-center contain__header">
                <div style={{width: '150px'}}>
                    <img src={configTheme.logo} alt="Logo" width="100%" />
                </div>
              </Card.Header>
              <Card.Body>
                <Formik 
                  initialValues={initialValues}
                  validate={this.validate((values) => validationSchema(values))}
                  onSubmit={(values) => {this.linkToPay(values)}}
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    handleBlur,
                    handleReset,
                  }) => (
                    <Form onSubmit={handleSubmit} noValidate>
                      <Form.Row>
                        <Col sm="12" md="6" className="contain__formInput">
                          <Form.Label htmlFor="nombre" className="contain__formLabel">Nombre</Form.Label>
                          <Form.Group className="contain__formInput-width">
                            <Form.Control
                              id="nombre"
                              type="text"
                              name="nombre"
                              value={values.nombre}
                              onChange={handleChange}
                              placeholder="Nombre(s)"
                              className="contain__formInput-details"
                            />
                            {errors.nombre && touched.nombre && <div className="input-feedback">{errors.nombre}</div>}
                          </Form.Group>
                        </Col>
                        <Col sm="12" md="6" className="contain__formInput">
                          <Form.Label htmlFor="apellido" className="contain__formLabel">Apellido</Form.Label>
                          <Form.Group className="contain__formInput-width">
                            <Form.Control
                              id="apellido"
                              type="text"
                              name="apellido"
                              value={values.apellido}
                              onChange={handleChange}
                              placeholder="Apellido(s)"
                              className="contain__formInput-details"
                            />
                            {errors.apellido && touched.apellido && <div className="input-feedback">{errors.apellido}</div>}
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col sm="12" md="12" className="contain__formInput">
                          {/* <OverlayTrigger
                              key='top'
                              placement='top'
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  Nro. Referencia (No. de factura, concepto, etc).
                                </Tooltip>
                              }
                            > */}
                              <Form.Label htmlFor="numeroReferencia" className="contain__formLabel-hiddenText">Nro. Referencia o Factura</Form.Label>
                            {/* </OverlayTrigger> */}
                          <Form.Group className="contain__formInput-width">
                            <Form.Control 
                              id="numeroReferencia"
                              type="text"
                              name="numeroReferencia"
                              value={values.numeroReferencia}
                              onChange={handleChange}
                              placeholder="No Referencia (No. de factura, concepto, etc)"
                              className="contain__formInput-details"
                            />
                            {errors.numeroReferencia && touched.numeroReferencia && <div className="input-feedback">{errors.numeroReferencia}</div>}
                          </Form.Group>
                        </Col> 
                      </Form.Row>
                      <Form.Row>
                        <Col sm="12" md="6" className="contain__formInput">
                          <Form.Label htmlFor="cedula" className="contain__formLabel">Cédula - NIT</Form.Label>
                          <Form.Group className="contain__formInput-width">
                            <Form.Control 
                              id="cedula"
                              type="number"
                              name="cedula"
                              value={values.cedula}
                              onChange={handleChange}
                              placeholder="Número de cédula - NIT"
                              className="contain__formInput-details"
                            />
                            {errors.cedula && touched.cedula && <div className="input-feedback">{errors.cedula}</div>}
                          </Form.Group>
                        </Col>
                        <Col sm="12" md="6" className="contain__formInput">
                          <Form.Label htmlFor="monto" className="contain__formLabel">Monto</Form.Label>
                          <Form.Group className="contain__formInput-width" style={{position: 'relative'}}>
                            <i className="cil-dollar icon__amount"></i>
                            <Field 
                              id="monto"
                              type="text"
                              name="monto"
                              value={`${values.monto}`}
                              onChange={handleChange}
                              placeholder="Monto"
                              validate={this.validateNumber}
                              className="contain__formInput-details monto"
                            />
                            {errors.monto && touched.monto && <div className="input-feedback">{errors.monto}</div>}
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col sm="12" md="6" className="contain__formInput">
                          <Form.Label htmlFor="telefono" className="contain__formLabel">Teléfono</Form.Label>
                          <Form.Group className="contain__formInput-width">
                            <InputMask
                              id="telefono"
                              name="telefono"
                              value={values.telefono}
                              onChange={handleChange}
                              placeholder="Teléfono"
                              className="contain__formInput-details"
                              mask="\ 99 9999-9999"
                            />
                            {errors.telefono && touched.telefono && <div className="input-feedback">{errors.telefono}</div>}
                          </Form.Group>
                        </Col>
                        <Col sm="12" md="6" className="contain__formInput">
                          <Form.Label htmlFor="email" className="contain__formLabel">Email</Form.Label>
                          <Form.Group className="contain__formInput-width">
                            <Form.Control 
                              id="email"
                              type="email"
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                              placeholder="abc@abc.com"
                              className="contain__formInput-details"
                            />
                            {errors.email && touched.email && <div className="input-feedback">{errors.email}</div>}
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Col sm="12" md="12" className="contain__formInput">
                          <Form.Label htmlFor="observaciones" className="contain__formLabel">Observaciones</Form.Label>
                          <Form.Group className="contain__formInput-width">
                            <Form.Control 
                              id="observaciones"
                              type="text"
                              name="observaciones"
                              value={values.observaciones}
                              onChange={handleChange}
                              className="contain__formInput-details"
                              as="textarea"
                              rows="3"
                              maxLength={120}
                            />
                            {errors.observaciones && touched.observaciones && <div className="input-feedback">{errors.observaciones}</div>}
                          </Form.Group>
                        </Col>
                      </Form.Row>
                      <Form.Row>
                        <Button variant="primary" type="submit" className="contain__formButton" style={{background: `${configTheme.primary_color}`}} disabled={this.state.disabled}>
                          {(() => {
                            if(this.state.loading) {
                              return (<Spinner animation="border" size="sm" />)
                            }
                          })()}
                          Pagar
                        </Button>
                      </Form.Row>
                    </Form>
                  )}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Contain;
