import React from "react";

export class FormSchema extends React.Component {
  validate = getValidationSchema => {
    return values => {
      const validationSchema = getValidationSchema(values);
      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return this.getErrorsFromValidationError(error);
      }
    };
  };

  onSubmit_Test = (values, { setSubmitting, setErrors }) => {
    setTimeout(() => {
      alert(JSON.stringify(values, null, 2));
      // console.log('User has been successfully saved!', values)
      setSubmitting(false);
    }, 2000);
  };

  getErrorsFromValidationError = validationError => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  findFirstError(formName, hasError) {
    const form = document.forms[formName];
    for (let i = 0; i < form.length; i++) {
      if (hasError(form[i].name)) {
        form[i].focus();
        break;
      }
    }
  }

  validateFormName(errors, formName) {
    this.findFirstError(formName, fieldName => {
      return Boolean(errors[fieldName]);
    });
  }

  /*
  touchAll(setTouched, errors) {
    setTouched({
      firstName: true,
      lastName: true,
      userName: true,
      email: true,
      password: true,
      confirmPassword: true,
      accept: true
    });
    this.validateForm(errors);
  }
  */

  touchAllSet(setTouched, errors, initialValues, formName) {
    const keys = Object.keys(initialValues);
    let keyInitialValues = {};
    keys.map(key => {
      keyInitialValues[key] = true;
      return true;
    });

    setTouched({
      ...keyInitialValues
    });
    this.validateFormName(errors, formName);
  }
}
