import React from 'react';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import  UpperLine from '../PageBuilder/UpperLineBrand';
import Contain from '../PageBuilder/Contain';
import Footer from '../PageBuilder/Footer';
import NotFoundPage from "../Page/NotFound";
import 'bootstrap/dist/css/bootstrap.min.css';

function MainContainer() {
  return (
    <Container className="App" fluid>
      <UpperLine />
      <div className="appWrapped">
        <BrowserRouter>
          <Switch>
            <Route
              component={Contain}
            />
            <Route 
              component={NotFoundPage}
            />
          </Switch>
        </BrowserRouter>
      </div>
      <Footer />
    </Container>
  );
}

export default MainContainer;
