import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Theme from './Themes';
let theme = Theme.getThemeByLocation()

const Footer = () => {
  return (
    <div className="footer" style={{background: `${theme.primary_color}`}}>
      <Row>
        <Col sm="12" md="12"><p>2020 Paymentez</p></Col>
      </Row>
    </div>
  )
}

export default Footer; 
